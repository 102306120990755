@font-face {
  font-family: 'Chillax Bold';
  src: url('./Components/assets/Fonts/Chillax/Chillax-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Chillax medi';
  src: url('./Components/assets/Fonts/Chillax/Chillax-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Manhattan Hand Bold';
  src: url('./Components/assets/Fonts/Monhattan/ManhattanHandBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Manhattan Hand Medi';
  src: url('./Components/assets/Fonts/Monhattan/Manhattan\ Hand\ Medium.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
html{
  scroll-behavior: auto !important;
} body {
  scroll-behavior: auto !important;
  margin: 0;
  background: #171717;
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.topbarContainer {
  padding: 3% 0;
}
nav.navbar.navbar-expand-lg.navbar-light {
  padding: 18px 0px;
}
.navbar-nav .menuicons {
  display: flex;
  align-items: center;
  gap: 40px;
}
.navbar-nav .menuicons svg {
  font-size: 20px;
  color: white;
} 
.menunav.navbar-nav a.nav-link.active {
  color: #fff;
  border-bottom: 1px solid #FEC831;
}
.menunav.navbar-nav a.nav-link.sub.active {
  border-bottom: none;
}
.navbar-expand-lg .navbar-nav{
  gap: 20px;
}
.menunav.navbar-nav a.nav-link {
  font-family: 'Chillax medi';
  color: #c7c7c7bf;
  text-transform: uppercase;
}

.banner-hero .row {
  width: 100%;
}
.bg-banner {
  background-image: url("./Components/assets/images/bg-banner.png") !important;
  background-size: contain !important;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-position-y: center !important;
  background-attachment: fixed !important;
}
.banner-hero{
  display: flex;
  justify-content: space-around;
  height: 50vh;
  background: url('./Components/assets/images/banner.png');
  background-size: 100% 100% !important;
  box-shadow: 0px 0px 6px 1px #7f7f7f;
  padding-bottom: 15px;
  border-radius: 26px;
}
.banner-hero .bennercol{
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  text-align: center;
}
.coffeeBanner{
  display: flex;
  justify-content: space-around;
  height: 50vh;
  background: url('./Components/assets/images/coffeeBanner.png');
  background-size: 100% 100% !important;
  box-shadow: 0px 0px 6px 1px #7f7f7f;
  padding-bottom: 15px;
  border-radius: 26px;
}
.coffeeBannerAbout{
  display: flex;
  padding: 35px 0px;
  /* justify-content: space-around; */
  height: 50vh;
  background: url('./Components/assets/images/contactus.jpg');
  background-size: 100% !important;
  box-shadow: 0px 0px 6px 1px #7f7f7f;
  padding-bottom: 15px;
  border-radius: 26px;
  background-position-y: center;
}
.productPage .container .productBoxDescription .productSubsButton .singleButton {
  border: none;
  font-family: 'chillax medi';
  background: #fff;
  color: black;
  padding: 7px 23px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
}
.singleButton {
  background: #FEC831 !important;
}
.productPage .container .productBoxDescription .productSubsButton .singleButton span {
  font-family: 'poppins';
    font-size: 14px;
    font-weight: 500;
}
.productPage .container .productBoxDescription .productSubsButton .subsButton {
  background: #FEC831;
  color: black;
  padding: 7px 23px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  border: none;
  font-family: 'chillax medi';
}
.subsButton {
  background: #fff !important;
}
.subsButton:hover {
  background: #FEC831 !important;
}
.productPage .productBoxDescription .selectedProduct .price .input-group .input-group-prepend button{
  background: #FEC83121;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  
}
.productPage .productBoxDescription .selectedProduct .price .input-group .input-group-prepend{
  padding: 5px;
}
.productPage .productBoxDescription .selectedProduct .price .input-group .input-group-prepend{
  border: none;
}
.productPage .productBoxDescription .selectedProduct .price .input-group {
  background: transparent;
  border-radius: 100px;
  border: 1px solid #FEC831;
}
 .productPage .productBoxDescription .detailsOfSelectedProduct{
  margin-top: 20px;
 }
 .productPage .productBoxDescription .detailsOfSelectedProduct .fnlDetails{
  
  font-size: 24px;
 }
 .productPage .productBoxDescription .detailsOfSelectedProduct h4{
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  color: white;
  text-align: left;
  font-size: 18px;
 }
 .productPage .productBoxDescription .detailsOfSelectedProduct h5{
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  color: white;
  text-align: right;
  font-size: 18px;
 }
 .detailsOfSelectedProduct ul{
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  color: white;
  text-align: right;
  font-size: 18px;
  text-decoration: none;
  list-style: none;
 }
 .productPage .productBoxDescription .selectedProduct .price .input-group .form-control{
  border: none;
  background: transparent;
  color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
 }
 .productPage .productBoxDescription .selectedProduct .imgBox img{
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
 }
 .productPage .productBoxDescription .selectedProduct .textsec h4{
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';

  font-size: 18px;
  font-weight: 500;
  color: white;
 }
 .productPage .productBoxDescription .selectedProduct .textsec h4, .productPage .productBoxDescription .selectedProduct .textsec p{
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';

  text-align: left;
  color: white;
  margin: auto;
}
/* .productPage .productBoxDescription .selectedProduct .textsec{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
} */
.productPage .productBoxDescription .selectedProduct{
  align-items: center;
  margin: auto;
  border-radius: 10px;
  background: #48412D;
}
.productPage .productBoxDescription .productBoxOne .productDescriptionOne h3{
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';

  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF; 
}
.productBoxOne:hover {
  background-color: rgb(72, 65, 45) !important;
}
.productPage .addtocartButton{
  background-color: #FEC831;
  color: black;
  padding: 7px 23px;
  border-radius: 8px; 
  font-weight: 500;
  font-size: 20px;
  border: none;
  font-family: 'chillax medi';
}
.productPage .productBoxDescription .productBoxOne .imgBox{
  height: 130px;
}
.productPage .productBoxDescription .productBoxOne .imgBox img{
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  height: 100%;
}
.subscriptionStep3Product .subscriptionStep3ProductCol .img-box{
  height: 200px;
}
.productPage .productBoxDescription .productBoxOne{
  background-color: #424242;
  display: flex;
  flex-direction: column;
  gap: 11px;
  /* height: 200px; */
  justify-content: space-between;
  padding: 14px 10px;
  border-radius: 10px;
  text-transform: uppercase;
}
.sidebar .sidebar_widget .widget_title:hover {
  color: #fec831;
}
.alert.alert-dangerStock.h5.text-center {
  background: #323232;
  border: 2px solid #fec831;
  color: #fec831;
}
.productPage .container .writterDescription {
  text-align: left;
}
.productPage .container .writterDescription h2{
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';

  font-size: 24px;
  font-weight: 500;
  color: #FFFFFF;
}
.productPage .container .writterDescription p{
  font-family: "Poppins", sans-serif;

  font-size: 16px;
  color: #FFFFFF;
}
.productPage .container .productBoxDescription .productSubsButton .subsButton span {
  font-family: 'poppins';
  font-size: 14px;
  font-weight: 500;
}
.footerMenu {
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
}
.productPage .container .productBoxDescription .productSubsButton{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.productPage .container .productBoxDescription{
  padding: 15px 15px;
  border-radius: 10px;
  background: #323232;
}
.productPage .container{
background-size: 100% 100% !important;
border-radius: 10px;
}
.productPage .productBox img{
  width: 60%;
  object-fit: cover;
  border-radius: 8px;

}
/* section.main.coffeemain::after {
  content: '';
  right: 20px;
  width: 126px;
  background-size: 100% 100% !important;
  height: 126px;
  position: absolute;
  background: url('./Components/assets/images/leave.png');
  transform: translate(0px, -119px);
  z-index: 9999;
} */
.coffeeBanner .coffeeBennercol{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.coffeeBanner .coffeeBennercolAbout{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.branding {
  margin-top: 60px !important;
}
.main.SecondAboutSec {
  margin-top: 60px;
  text-align: left;
}
.main.SecondAboutSec p{
  font-size: 16px;
  color: #BCBCBC;
  font-family: 'montserrat';
}
.main.SecondAboutSec h2{
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    
    color: #FFFFFF;

}
.main.SecondAboutSec h3 {
  margin-top: 20px;
  font-size: 22px;
  color: #BCBCBC;
  font-family: 'montserrat';
}
.main.SecondAboutSec h3 span {
  font-size: 16px;
  color: #BCBCBC;
}
.coffeeBanner .coffeeBennercol h1{
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    
    color: #FFFFFF;
}
.coffeeBanner .coffeeBennercol h1 span{
  color: #B88369;
}
.coffeeBannerAbout .coffeeBennercolAbout h1{
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    
    color: #FFFFFF;
}
.coffeeBannerAbout .coffeeBennercolAbout h1 span{
  color: #B88369;
}
.banner-hero .social-icon{
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 20px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  section.checkoutSec .checkoutCol2 .productDetails h5{
    font-size: 20px;
  }
  section.checkoutSec .checkoutCol2 .productDetails p{
    font-size: 13px;
  }
  section.sectionPayment{
    padding: 20px 10px !important;
  }
  section.sectionPayment .checkoutCol .row.columnreverse {
    flex-direction: column-reverse;
}
  section.checkoutSec .checkoutCol1 .productDetails h5{
    font-size: 16px;
  }
  section.checkoutSec .checkoutCol1 .productDetails p{
    font-size: 13px;
  }
  section.checkoutSec .checkoutCol1 .cartItem{
    gap: 8px;
  }
  section.contact-section .columnMap{
    border-right: none !important;
  }
  section.contact-section .contact-form {
    padding: 1em 0em 3em 1em !important;
}
  section.contact-section .bordercolor {
    /* border: none !important; */
    border-bottom: none;
    border-left: none;
    border-right: none;
}
  section.footerSection{
    padding: 60px 6px 0px 6px !important;
  }
  .footerSection .footerMenu ul li{
    list-style: circle;
  }
  .subscriptionBanner{
    height: 35vh;
  }
  .special-online-shop .special-online-shop-box .img-box{
    height: 125px !important;
  }
  div#responsive-navbar-nav {
    background: #f8c62033;
    text-align: left;
    padding: 13px 30px;
    
    border-radius: 10px;
}
.productPage .container .productBoxDescription{
  padding: 11px 7px;
}
.productPage .productBoxDescription .productBoxOne{
  gap: 7px;
    padding: 4px 3px;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.productPage .productBoxDescription .selectedProduct{
  padding: 10px 0px;
}
.container.coffeeBanner{
  border-radius: inherit;
  background-size: 100% 100% !important;
  height: 30vh;  
}
.container.coffeeBannerAboutus{
  border-radius: inherit;
  background-size: 100% 100% !important;
  height: 30vh;  

}
.coffeeBannerAboutus .coffeeBennercol h1{
  font-size: 35px !important;
}

.navbar-expand-lg .secondMenu.navbar-nav .menuicons {
  justify-content: center;
  margin: 22px 0px;
}
.packageContainerMargin{
  height: auto !important;
  padding: 17px 0px;
  gap: 16px 0px;
}
.coffee-slect-text{
  
  font-size: 10px !important;
}
.subscriptionStep3Product .subscriptionStep3ProductCol .img-box{
  height: 105px;
}
.coffeeBanner .coffeeBennercol h1{
  font-size: 35px !important;
}
.bg-banner{
  border-radius: 0px !important;
}
.main.SecondAboutSec h2{
  font-size: 35px;
}
.coffeeBannerAbout .coffeeBennercolAbout h1{
  font-size: 35px;
}
.container.coffeeBannerAbout {
  border-radius: inherit;
  background-size: 100% 100% !important;
  height: 30vh;
}
.productPage .productBoxDescription .productBoxOne .imgBox{
  height: 75px;
}
.productPage .productBoxDescription .productBoxOne .productDescriptionOne h3{
  font-size: 18px;
}
  .banner-hero{
    background-size: 100% !important;
    border-radius: 0px;
    height: 35vh;
  }
  .subscriptionBanner .subscriptionBannerCol h2{
    font-size: 30px !important;
  }
  .container.banner-hero h1{
    font-size: 30px !important;
  }
  
}

.container.banner-hero h1{
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    
    color: #FFFFFF;
}
.container.banner-hero h1 span{
  color: #FEC831;
}
.special-online-shop .subtitle{
  margin-top: 20px;
  line-height: 0px;
  text-align: left;
}
p{
  margin-bottom: 5px !important;
}
.special-online-shop p{
  color: #FFFFFF;
}
.special-online-shop h2{
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    
    color: #FFFFFF;
}
.SpecialOnlineShop1 {
  padding: 4% 0;
}
.footerSection h2.h2footer{
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 38px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    color: #FFFFFF;
}
.footerSection .subscribe {
  border-radius: 100px;
  display: flex;
  margin-top: 20px;
  background: #ebebeb38;
  align-items: center;
  color: white;
  justify-content: center;
}
.footerSection .subscribe input::placeholder {
  color: #979797;
}
.footerSection .subscribe input {
  width: 100%;
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  color: white;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background: none;
}
.footerSection .subscribe button {
  width: 100%;
  padding: 10px;
  border-radius: 100px;
  border: none;
  background: #FEC831;
  color: black;
  width: 50%;
  font-weight: 500;
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
}
:focus-visible {
  outline: sienna!important;
}
.footerSection h2.h2footer span{
    color: #FEC831;
}
.special-online-shop h2 span{
  color: #FEC831;
}
.special-online-shop .special-online-shop-box svg{
  font-size: 48px;
  color: white;
}
/* .special-online-shop .special-online-shop-box:hover{
  cursor: pointer;
} */
.special-online-shop .special-online-shop-box:hover h4, .special-online-shop .special-online-shop-box:hover svg{
  color: #FEC831;
}
.special-online-shop .special-online-shop-box h4, .special-online-shop .special-online-shop-box p{
  color: #FFFFFF;
}
.special-online-shop .special-online-shop-box{
  display: flex;
    flex-direction: column;
    height: auto;
    justify-content: end;
    margin-top: 20px;
    /* background: #202020; */
    align-items: center;
    border-radius: 8px;
    padding: 3px 12px;
}
.special-online-shop .special-online-shop-box .subtitleBox img{
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.special-online-shop .special-online-shop-box .subtitleBox{
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.special-online-shop .special-online-shop-box img {
  width: 80%;
  object-fit: cover;
}
.special-online-shop .products{
  /* margin-top: 100px; */
  justify-content: center;
}
.Auth-form ul#uncontrolled-tab-example li.nav-item{
width: 50%;
}
ul#uncontrolled-tab-example.nav-tabs .nav-link.active{
  font-family: "Chillax Bold", Sans-serif !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* width: 88% !important; */
  gap: 4px !important;
  background-color: #FEC831 !important;
  color: black !important;
  padding: 6px 40px;
  border-radius: 8px !important;
  height: 58px !important;
  border: none !important;
  font-family: 'chillax medi' !important;
}
ul#uncontrolled-tab-example.nav-tabs .nav-link{
  font-family: "Chillax Bold", Sans-serif !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* width: 88% !important; */
  gap: 4px !important;
  background-color: #fafafa !important;
  color: black !important;
  padding: 6px 40px;
  border-radius: 8px !important;
  border: 2px solid #FEC831 !important;
  height: 58px !important;
  border: none !important;
  font-family: 'chillax medi' !important;

  
}
.Auth-form .tab-content{
  flex-flow: row;
  border: 1px solid gray;
  background-color: rgba(87, 87, 87, 0);
  justify-content: left;
  padding: 0px 0px 20px;
  border-radius: 10px;
}
.Auth-form ul#uncontrolled-tab-example.nav-tabs .nav-link{
  background-color: transparent !important;
  width: 100%;
  color: white !important;
  padding: 10px 0px;
  height: auto !important;
}
.Auth-form ul#uncontrolled-tab-example.nav-tabs .nav-link.active{
  width: 100%;
  background-color: gray !important;
  color: white !important;
}
.Auth-form ul#uncontrolled-tab-example {
  
  flex-flow: row;
  border: 1px solid gray;
  background-color: rgba(87, 87, 87, 0);
  justify-content: left;
  padding: 0px;
  border-radius: 10px;
}
ul#uncontrolled-tab-example {
  justify-content: center;
  gap: 19px;
  border: none;
}
.special-online-shop .col-md-3.view-all a {
  padding: 9% 14%;
  background: #FEC831;
  color: black;
  text-decoration: auto;
  font-weight: 500;
  border-radius: 8px;
}
/* @media screen and (max-width: 992px) {
  .special-online-shop .col-md-3.view-all a {
    padding: 15% 14%;
    font-size: small;
  }
} */

section.footerSection {
  /* border-bottom: 53px solid #fec831; */
  margin-top: 100px;
  /* background: #1f1f1f; */
  background: #000;
  padding: 20px 0px 0px 0px;
  text-align: left;
  color: white;
  position: relative;
  z-index: 0;
}
.lastFoot{
  background: #fec831;
}
.footerSection .footerMenu ul {
  padding: 10px 0;
  list-style-type: none;
  list-style: none;
  text-decoration: none;
  color: white;
}
.footerUl {
  padding: 10px 0;
}
.footerSection .footerMenu ul li {
  list-style-type: none;
  margin-top: 10px;
}
.footerSection .footerMenu ul li a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  margin-top: 11px;
}
.callToAction{
  display: flex;
  gap: 10px;
}
.socialMediaLink {
  height: 50px;
  width: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copyrightText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
}
.copyrightText h6 {

  font-size: 15px;
  color: black;
  margin: auto;
}
.copyrightText h6 span {
  color: #000;
  font-weight: 900;
}
/* .yellowBottom {
  background-color: #FEC831;
  height: 20px;
  width: auto;
} */
.veteranClass {
  margin: auto !important;
}
.veteranClass img {
  object-fit: contain;
    width: 100%;
}
.subscriptionBanner{
  display: flex;
  justify-content: center;
  text-align: center;
  background: transparent;
  background-size: 100% 100% !important;
  /* box-shadow: 0px 0px 6px 1px #7f7f7f; */
  padding-bottom: 15px;
  border-radius: 26px;
  align-items: center;
}
.subscriptionBanner .subscriptionBannerCol h2{
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFFFFF;
}
.subscriptionSubs{
  display: flex;
  margin-top: 20px;
  justify-content: center;
  text-align: center;
  /* box-shadow: 0px 0px 6px 1px #7f7f7f; */
  align-items: center;
}
.subscriptionSubs .subscriptionSubsCol{
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}
.subscriptionSubs .subscriptionSubsCol h2{
  font-family: "Montserrat", sans-serif,
  sans-serif;
  font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: auto;
}
.subscriptionStep1{
  /* gap: 20px; */
  margin-top: 20px;
  text-align: center;
}
.subscriptionStep1 .subscriptionStep1Col{
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}
.subscriptionStep1 .subscriptionStep1Col h2{
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: auto;
}
.subscriptionStep1 .subscriptionStep1Col p{
  font-family:  "Montserrat", sans-serif;
  font-size: 26px;
  font-weight: 800;
  color: #FEC831;
  text-align: center;
  text-transform: uppercase;
}
.subscriptionStep1Product .subscriptionStep1ProductCol h3{
  font-family: 'chillax medi';
  margin-bottom: auto;
  color: white;
  font-size: 22px;
  text-transform: uppercase;
}
.flagClass {
  margin: auto;
}
.flagClass img {
  object-fit: contain;
    width: 100%;
}
.navbar {
    --bs-navbar-toggler-icon-bg: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23FFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E) !important;
}
@media only screen and (max-width: 992px){
 
  section.contact-section .bordercolor {
      border: none !important;
  }
  section.contact-section .columnMap {
    border: none !important;
}
}
@media only screen and (max-width: 768px){
  .special-online-shop .col-md-3.view-all a {
    padding: 8% 14%;
    font-size: small;
  }
.navbar-toggler{
  border: none !important;
}
.special-online-shop .col-md-3.view-all a {
  font-size: 15px;
  padding: 17px 10px;
  height: auto;
}
.seeAllProduct {
  width: 38% !important;
  margin: auto;
  margin-top: 40px !important;
}
.special-online-shop .special-online-shop-box h4, .special-online-shop .special-online-shop-box p {
  font-size: 15px;
  margin-bottom: 0px;
  /* margin-top: auto; */
}
.special-online-shop .special-online-shop-box svg{
  font-size: 30px;
  /* margin-top: auto; */
}
.special-online-shop .special-online-shop-box h4, .special-online-shop .special-online-shop-box p {
  font-size: 16px;
}
.footerSection h2.h2footer {
  font-size: 29px;
}
.subtitle h2 {
  font-size: 40px;
}
.footerLogo img {
  width: 275px !important;
}

.footerLogo {
  margin: 12px 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.copyrightText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.special-online-shop h2 {
  font-size: 24px;
  margin-top: 10px;
  }
  .copyrightText  {
    font-size: 20px;
  }
  .subscriptionStep3ProductCol  img{
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .subscriptionStep3Product .subscriptionStep3ProductCol{
    gap: 6px !important;
  }
  .subscriptionStep4 .subscriptionStep1Product .subscriptionStep1ProductCol h3{
    font-size: 30px !important;
  }
  .subscriptionStep4 .subscriptionStep1Product .subscriptionStep1ProductCol{
    height: 145px !important;
  }
  .subscriptionStep4 .subscriptionStep1Product .subscriptionStep1ProductCol.selected{
    height: 145px !important;

  }
  .subscriptionStep3Product .subscriptionStep3ProductCol h3 {
    font-size: 15px !important;
}
.subscriptionStep3Product .subscriptionStep3ProductCol p {
  font-size: 13px !important;
}
.step4Button p {
  font-size: 12px !important;
}
.step4Button svg {
  width: 8px !important;
}
}

@media only screen and (min-width: 768px) {
  .special-online-shop .col-md-3.view-all a {
    font-size: 15px;
  }
}
.subscriptionStep1Product .subscriptionStep1ProductCol p{
  font-family: 'chillax medi';
  color: white;
  margin-bottom: auto;
  text-transform: uppercase;
}
.subscriptionStep1Product .subscriptionStep1ProductCol.selected h3{
  color: #171717;
}
.subscriptionStep1Product .subscriptionStep1ProductCol.selected p{
  color: #171717;
}
.subscriptionStep1Product .subscriptionStep1ProductCol.selected svg{
  color: #171717 !important;
}
.subscriptionStep1Product .subscriptionStep1ProductCol.selected{
  background-color: #FEC831;
}
.subscriptionStep1Product .subscriptionStep1ProductCol{
  cursor: pointer;
  height: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 14px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #2D2D2D;
    border-radius: 12px;
}
.subscriptionStep1Product .subscriptionStep1ProductCol.step2{
  cursor: pointer;
  height: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 14px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: transparent;
    border-radius: 12px;
}
.subscriptionStep3Product .subscriptionStep3ProductCol h3{
  font-family: 'chillax medi';
  margin-bottom: auto;
  color: white;
  font-size: 16px;
}
.subscriptionStep3Product .subscriptionStep3ProductCol p{
  font-family: 'chillax medi';
  color: #fec831;
  margin-bottom: auto;
  font-size: 14px;
}
.subscriptionStep3Product .subscriptionStep3ProductCol{
  /* height: 300px; */
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 14px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #2D2D2D;
    border-radius: 12px;
    padding: 16px 21px;
}
.subscriptionStep3Product .subscriptionStep3ProductCol .price .input-group .input-group-prepend button{
  background: #FEC83121;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  
}

.subscriptionStep3Product .subscriptionStep3ProductCol .price .input-group .input-group-prepend{
  padding: 5px;
}
.subscriptionStep3Product .subscriptionStep3ProductCol .price .input-group .input-group-prepend{
  border: none;
}
.subscriptionStep3Product .subscriptionStep3ProductCol .price .input-group {
  background: transparent;
  border-radius: 100px;
  border: 1px solid #FEC831;
}
.subscriptionStep3Product .subscriptionStep3ProductCol .price .input-group .form-control {
  border: none;
  background: transparent;
  color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
}
.backButton{
  background-color: #FEC831;
    color: black;
    padding: 7px 23px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    border: none;
    font-family: 'chillax medi';
}
.proceedCheckout{
  background-color: #FEC831;
    color: black;
    padding: 7px 23px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    border: none;
    font-family: 'chillax medi';
}
.proceedCheckout3{
  text-transform: uppercase;
  background-color: #FEC831;
  color: black;
  padding: 7px 23px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  border: none;
  font-family: "Montserrat", sans-serif;
  }
.subscriptionStep4 .subscriptionStep1Col h2 {
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: auto;
}
.subscriptionStep4 .subscriptionStep1Col p {
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  font-weight: 800;
  color: #FEC831;
  text-align: center;
  text-transform: uppercase;
}

.subscriptionStep4  .productBoxDescription {
  padding: 15px 15px;
  border-radius: 10px;
  background: #323232;
}
.subscriptionStep4 .productBoxDescription .detailsOfSelectedProduct {
  margin-top: 20px;
}
.subscriptionStep4 .productBoxDescription .detailsOfSelectedProduct h4 {
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  color: white;
  text-align: left;
  font-size: 18px;
}
.subscriptionStep4 .productBoxDescription .detailsOfSelectedProduct h5 {
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  color: white;
  text-align: right;
  font-size: 18px;
}
.subscriptionStep4 .productBoxDescription .detailsOfSelectedProduct .fnlDetails {
  font-size: 24px;
}
.subscriptionStep4 .subscriptionStep1Product .subscriptionStep1ProductCol h3 span{
  font-size: 30px;
}
.subscriptionStep4 .subscriptionStep1Product .subscriptionStep1ProductCol h5 {
  color: white;
  font-size: 15px;
  text-align: center;
  padding-top: 16px;
}
.subscriptionStep4 .subscriptionStep1Product .subscriptionStep1ProductCol h3 {
  font-family: 'chillax medi';
  margin-bottom: auto;
  color: white;
  font-size: 50px;
  line-height: 33px;
}
.subscriptionStep4 .subscriptionStep1Product .subscriptionStep1ProductCol {
  height: 200px;
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: #2D2D2D;
  border-radius: 12px;
}
.subscriptionStep4 .subscriptionStep1Product .subscriptionStep1ProductCol.selected {
  height: 200px;
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: #FEC831 ;
  border-radius: 12px;
}
.subscriptionStep4 .subscriptionStep1Product .subscriptionStep1ProductCol.selected h5 {
  color: #171717;
}
.subscriptionStep4 .subscriptionStep1Product .subscriptionStep1ProductCol.selected h3 {
  color: #171717;
}


section.contact-section .bordercolor{
  border-top: 2px solid white;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
section.contact-section .contact-form #contact-form .contactButton{
  text-align: left;
  margin-top: 20px;
}
section.contact-section .contact-form #contact-form .contactButton button{
  font-family: "Chillax Bold", Sans-serif;
  font-size: 15px;
  padding: 10px;
  /* border-radius: 100px; */
  border: none;
  background: #FEC831;
  color: black;
  width: 50%;
  font-weight: 500;
}
section.contact-section .contact-form #contact-form {
  margin-top: 30px;
}
section.contact-section .contact-form #contact-form input, section.contact-section .contact-form #contact-form textarea{
  width: 100%;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 3px;
  background: none;
  color: white;
  border: 1px solid #ddd;
}
section.contact-section .contact-form .slug{
  color: #FEC831;
  text-align: left;
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 40px;
}
section.contact-section .contact-form{
  padding: 20px 0px 3px 20px;
}
section.contact-section .columnMap{
  /* border-top: 2px solid white; */
  border-right: 2px solid white;
  padding: 20px;
}
.coffeeBannerAboutus{
  display: flex;
  justify-content: flex-start;
  height: 50vh;
  background: url('./Components/assets/images/contactus.jpg');
  background-size: 100% !important;
    background-position: right;
  box-shadow: 0px 0px 6px 1px #7f7f7f;
  padding-bottom: 15px;
  border-radius: 26px;
}
.coffeeBannerAboutus .coffeeBennercol{
  display: flex;
  align-items: center;
  
  margin-top: 40px;
  /* justify-content: center; */
  flex-direction: column;
  text-align: center;
}
.coffeeBannerAboutus .coffeeBennercol h1{
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    
    color: #FFFFFF;
}
.coffeeBannerAboutus .coffeeBennercol h1 span{
  color: #B88369;
}
.form-control:focus{
  box-shadow: none;
}
section.checkoutSec {
  margin-top: 50px;
}
section.checkoutSec .checkoutCol .formCheckout form .form-control::placeholder{
  color: #D9D9D9;
  /* font-size: 20px; */
  font-weight: 400;
  text-align: left;
  
}
section.checkoutSec .checkoutCol .formCheckout.rendering form .form-control{
  background: #D9D9D936;
}
section.checkoutSec .checkoutCol .formCheckout form .form-control{
  background: #FFD89D30;
  padding: 10px 10px;
  color: white;
  /* border: none; */
  font-family: "Outfit", sans-serif;
  border-radius: 4px;
  border: 1px solid #FFD89D4A;
}

section.checkoutSec .checkoutCol .formCheckout h2{
  font-size: 20px;
}
section.checkoutSec .checkoutCol .formCheckout{
  margin-top: 25px;
    background: #211e1c;
    padding: 20px;
    margin-bottom: 27px;
    border-radius: 5px;
    
}
section.checkoutSec .checkoutCol h2{
  text-transform: uppercase;
  font-family: 'Chillax Bold';
}
section.checkoutSec .checkoutCol{
  /* padding: 10px 20px;
  background-color: #211E1C; */
  text-align: left;
  color: white;
  /* padding: 30px 20px; */
/* gap: 0px; */
/* border-radius: 5px; */
/* opacity: 0px; */

}

section.checkoutSec .checkoutCol1 .formCheckout1 form .form-control::placeholder{
  color: #D9D9D9;
  /* font-size: 20px; */
  font-weight: 400;
  text-align: left;
  
}
section.checkoutSec .checkoutCol1 .formCheckout1.rendering1 form .form-control{
  background: #D9D9D936;
}
section.checkoutSec .checkoutCol1 .formCheckout1 form .form-control{
  background: #FFD89D30;
  padding: 10px 10px;
  color: white;
  /* border: none; */
  font-family: "Outfit", sans-serif;
  border-radius: 4px;
  border: 1px solid #FFD89D4A;
}

section.checkoutSec .checkoutCol1 .formCheckout1 h2{
  font-size: 20px;
  color: white;
}
/* section.checkoutSec .checkoutCol1 .formCheckout1{
  margin-top: 50px;
} */
section.checkoutSec .checkoutCol1 h2{
  text-transform: uppercase;
  font-family: 'Chillax Bold';
}
section.checkoutSec .checkoutCol1 .productImage h5{
  margin-bottom: 0px;
}
section.checkoutSec .checkoutCol1 .productImage p{
  margin-bottom: 0px;
}
section.checkoutSec .checkoutCol1 .productImage{
  display: flex;
  gap: 10px;
  align-items: center;
}
section.checkoutSec .checkoutCol1{
  padding: 10px 20px;
  background-color: #4B4135;
  display: contents;
  text-align: left;
  color: black;
  padding: 30px 20px;
gap: 0px;
border-radius: 5px;
opacity: 0px;
margin-top: 30px;
}
section.checkoutSec .checkoutCol1 .cartTable .cartHeader{
  color: #D9D9D9;
  font-family: "Outfit", sans-serif;
}
section.checkoutSec .checkoutCol1 .price .input-group .input-group-prepend button{
  background: #836B54;
  border-radius: 9px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  
}
section.checkoutSec .checkoutCol1 .price .input-group .input-group-prepend{
  border: none;
}
section.checkoutSec .checkoutCol1 .price .input-group {
  background: transparent;
  /* border-radius: 100px; */
  /* border: 1px solid #FEC831; */
}
section.checkoutSec .checkoutCol1 .price .input-group .form-control{
  border: 1px solid transparent;
    background: #0D0D0D1A;
    color: black;
    border-radius: 20px !important;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
 }
 .checkoutCol1 {
  background: #4B4135;
  color: white;
  border-radius: 5px;
  padding: 30px 20px;
}

.formCheckout1 h2 {
  text-transform: uppercase;
  margin-bottom: 20px;
}

section.checkoutSec .checkoutCol1 .cartTable {
  border-radius: 10px;
  overflow: hidden;
}

section.checkoutSec .checkoutCol1 .cartHeader {
  display: flex;
  justify-content: space-between;
  background: none;
  padding: 10px 20px;
}

section.checkoutSec .checkoutCol1 .cartItem {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background: #FFD89D;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 10px;
}
.cancel{
  color: red;
  font-size: 20px;
}
section.checkoutSec .checkoutCol1 .productImage {
  width: 78px; /* adjust as needed */
  margin-right: 10px;
}

section.checkoutSec .checkoutCol1 .productDetails {
  display: flex;
  flex-direction: column;
}

section.checkoutSec .checkoutCol1 .productDetails h5 {
  margin: 0;
  font-family: 'Chillax Bold';
}

section.checkoutSec .checkoutCol1 .productDetails p {
  margin: 0;
  color: #836B54; /* lighter brown color for text */
}

section.checkoutSec .checkoutCol1 .productQuantity {
  display: flex;
  align-items: center;
  width: 30%;
}

section.checkoutSec .checkoutCol1 .quantityButton {
  background: #836B54;
    color: white;
    border: none;
    /* padding: 5px 10px; */
    cursor: pointer;
    border-radius: 9px;
    width: 30px;
}

section.checkoutSec .checkoutCol1 .quantityInput {
  text-align: center;
  margin: 0 -5px;
  width: 78px;
  border-radius: 25pc;
  z-index: 0;
  background: #0D0D0D1A;
  border: none;
}

section.checkoutSec .checkoutCol1 .productPrice {
  text-align: right;
  font-weight: bold;
}
section.checkoutSec .checkoutCol1 .formCheckout1 .cartTable .cartItem .productInfo{
  display: flex;
  align-items: center;
  gap: 0px;
}

section.checkoutSec .checkoutCol2 .formCheckout2 h2 {
  font-size: 20px;
  color: white;
}

section.checkoutSec .checkoutCol2.checkoutCol1 .formCheckout2 {
  background: #211E1C !important;
  text-align: left;
  color: black;
  padding: 30px 20px;
  gap: 0px;
  display: contents;
  border-radius: 5px;
  opacity: 0px;
  margin-top: auto;
}
section.checkoutSec .checkoutCol2 .formCheckout2 .cartDetails .detailsOfProduct{
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
section.checkoutSec .checkoutCol2 .formCheckout2 .cartDetails hr{
  background-color: #FFD89D;
  padding: 1px;
  border-color: #FFD89D;
}
section.checkoutSec .checkoutCol2 .formCheckout2 .cartDetails {
  margin-top: 30px;
}
section.checkoutSec .checkoutCol2 .formCheckout2 .cartDetails h2.totall{
  font-size: 25px;
}
section.checkoutSec .checkoutCol2 .formCheckout2 .cartDetails h2 {
  font-size: 18px;
  color: white;
  text-transform: capitalize;
  font-family: 'Chillax medi';
  font-weight: 500;
  margin-bottom: 8px;
}
section.checkoutSec .checkoutCol2 .cartTable .cartHeader {
  color: white;
}
section.checkoutSec .checkoutCol2{
  padding: 10px 20px;
    background-color: #211E1C;
    text-align: left;
    color: black;
    padding: 30px 20px;
    gap: 0px;
    border-radius: 5px;
    opacity: 0px;
    margin-top: auto;
    display: contents;
}
section.checkoutSec .brandLogo img{
  object-fit: contain;
  width: 38%;
}
section.checkoutSec .brandLogo {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 33px;
}
section.checkoutSec .checkoutCol2 .cartTable {
  border-radius: 10px;
  overflow: hidden;
}

section.checkoutSec .checkoutCol2 .cartHeader {
  display: flex;
  justify-content: space-between;
  background: none;
  padding: 10px 20px;
}

section.checkoutSec .checkoutCol2 .cartItem {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background: #FFD89D;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 10px;
}

section.checkoutSec .checkoutCol2 .productImage {
  width: 78px; /* adjust as needed */
  margin-right: 10px;
}

section.checkoutSec .checkoutCol2 .productDetails {
  /* width: 110px; */
  display: flex;
  flex-direction: column;
}

section.checkoutSec .checkoutCol2 .productDetails h5 {
  margin: 0;
  font-size: 16px !important;
}

section.checkoutSec .checkoutCol2 .productDetails p {
  margin: 0;
  color: #836B54; /* lighter brown color for text */
  font-size: 14px !important;
}

section.checkoutSec .checkoutCol2 .productQuantity {
  display: flex;
  align-items: center;
  width: 38%;
  justify-content: end;
}

section.checkoutSec .checkoutCol2 .quantityButton {
  background: #836B54;
    color: white;
    border: none;
    /* padding: 5px 10px; */
    cursor: pointer;
    border-radius: 9px;
    width: 30px;
}

section.checkoutSec .checkoutCol2 .quantityInput {
  text-align: center;
  margin: 0 -5px;
  width: inherit;
  border-radius: 25pc;
  z-index: 0;
  background: #0d0d0d00;
  border: none;
}

section.checkoutSec .checkoutCol2 .productPrice {
  text-align: right;
  font-weight: bold;
}
section.checkoutSec .checkoutCol2 .formCheckout2 .cartTable .cartItem .productInfo{
  display: flex;
  align-items: center;
  gap: 0px;
  width: 100%;
}
section.sectionPayment {
  background: url(./Components/assets/images/oneProductBanner.png);
  background-size: 100% 100% !important;
  border-radius: 10px;
  padding: 50px 50px;
}
section.sectionPayment .checkoutCol .formCheckout form .form-control::placeholder{
  color: #D9D9D9;
  /* font-size: 20px; */
  font-weight: 400;
  text-align: left;
  
}
section.sectionPayment .checkoutCol .formCheckout.rendering form .form-control{
  background: #D9D9D936;
}
section.sectionPayment .checkoutCol .formCheckout form .form-control{
  background: #FFD89D30;
  padding: 10px 10px;
  color: white;
  /* border: none; */
  font-family: "Outfit", sans-serif;
  border-radius: 4px;
  border: 1px solid #FFD89D4A;
}
.subscription-form {
  width: 300px;
  margin: 0 auto;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: #ff0000;
  margin-top: 5px;
}

.sectionPayment .paymentForm .checkoutCol .btnPayment {
    border-radius: 7px;
    background: #C7A17A;
    font-size: 19px;
    border: none;
    font-weight: 700;
    font-family: 'Chillax medi';
}
.paymentProduct .detailsOfSelectedProduct {
  margin-top: 20px;
}
.paymentProduct .detailsOfSelectedProduct h4{
  color: #C7A17A;
  font-family: 'Chillax medi';
  font-size: 20px;
}
.paymentProduct .detailsOfSelectedProduct h3{
  color: #C7A17A;
  font-family: 'Chillax medi';
  font-size: 23px;
  font-weight: 700;
}
.paymentProduct h2{
  font-family: 'Chillax medi';
  font-size: 25px;
  color: #C7A17A;
  font-weight: 700;
}
section.sectionPayment .checkoutCol .formCheckout h2{
  font-size: 20px;
}
section.sectionPayment .checkoutCol .formCheckout{
  background-color: #211e1c;
    border-radius: 5px;
    padding: 13px;
    margin-top: 50px;
}
section.sectionPayment .checkoutCol h2{
  text-transform: uppercase;
  font-family: 'Chillax Bold';
}
section.sectionPayment .checkoutCol{
  padding: 10px 20px;
  display: contents;
  background-color: #211E1C;
  text-align: left;
  color: white;
  padding: 30px 20px;
gap: 0px;
border-radius: 5px;
opacity: 0px;
}
a{
  text-decoration: auto !important;
}
section.sectionPayment .checkoutCol h4 {
  font-size: 16px;
}
.footerLogo {
  margin: 12px 0;
  padding: 0 10px;
}
.footerLogo img {
  width: 210px;
}
.footerContent {
  padding: 9px 0;
  margin: 12px 0;
}
.packageContainerMargin {
  margin-top: 20px !important;
  margin: auto  ;
  display: flex;
  border: 2px solid;
  justify-content: space-around;
  align-items: center;
    height: 95px;
    width: 100%;
    background: #121212;
}
.packageDiscounct {
  display: flex;
  justify-content: space-between;
  background-color: #1D1D1D;
  width: 60%;
  height: 100%;
}
.packageBoxes{
  color: white;
  background-color: #1d1d1d;
  border: 1px solid #fec831;
  /* border-style: solid; */
  border-radius: 5px;
  padding: 5px 6px !important;
  margin: 0 10px;
  height: 80px;
}
.packageBoxesDiv {
  justify-content: space-between;
}
/* .packageBoxes */
.packageShipmentText {
  font-family: "Chillax Bold", Sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 70%;
}
.step4Button.disabled{
background: #71706f;
color: white;
}
.step4Button.disabled p{
  color: black;
}
.backButton.disabled{
background: #71706f;
color: white;
}
.status{
  background: green;
    padding: 6px 14px;
    float: right;
    align-self: end;
    font-size: 14px;
}
.activeStatus{
  background: #fec831;
    padding: 6px 14px;
    float: right;
    align-self: end;
    font-size: 14px;
}
.table-responsive{
  width: 100%;
}
.totall {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0px;
}
.totall h5{
  color: white;
}
.step4Button{
  font-family: "Chillax Bold", Sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88%;
  gap: 4px;
  background-color: #FEC831;
  color: black;
  /* padding: 7px 23px; */
  border-radius: 8px;
  height: 58px;
  border: none;
  font-family: 'chillax medi';
}
.step4Button p {
  margin: 0 !important;
  font-size: 14px;
}
.step4Button svg {
  width: 11px;
}
.packageButtonDiv {
  align-items: center;
  margin-right: 20%;
  height: 100%;
}
.subscriptionBanner  {
  height: auto ;
}
.mapiFrame {
  object-fit: contain;
  border: 0px;
  width: 100%;
  border-radius: 12px;
}
.footerLeft {
  justify-content: center;
  margin: auto !important;
  align-items: center;
  display: flex;
}
.footerLeftDiv{
  transform: rotate(-20deg);
  margin: 30px 0;
}
.veteranText {
    /* border: solid 8px #e30d16; */
    /* border-style: double; */
    /* padding: 25px 20px; */
    /* margin: 4px 0;
    height: 73px;
    display: flex;
    justify-content: center; */
    width: 240px;
    border: solid 8px #e30d16;
    /* border-style: double; */
    /* padding: 25px 20px; */
    margin: 4px 0;
    height: 88px;
    display: flex;
    justify-content: center;
}
.veteranText h2{
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  margin: auto;
  font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    color: #e30d16;
}
.veteranTextBorder1 {
  border-top: 2px solid #e30d16;
  /* border-bottom: 3px solid #e30d16; */
}
.veteranTextBorder2 {
  /* border-top: 2px solid #e30d16; */
  border-bottom: 3px solid #e30d16;
}
.socialMediaIcon {
  color: #000;
  width: 20px;
  height: 20px;
}
.coffee-bag-quantity {
  display: flex;
  align-items: center;
}
 .coffee-bag-quantity-div {
  font-family: 'Chillax medi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen' !important;
  font-size: 20px !important;
  background-color: transparent !important;
  width: auto !important;
  border-radius: 0 !important;
  border: none !important;
  text-align: end !important;
  color: #e4f5f9 !important;
}
.coffee-bag-quantity h5{
  padding: 0;
  margin: 0;
}
.coffee-slect-text {
  font-size: 12px;
}
.header-icon {
  padding: 0 !important;
}
/* .Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding: 20px 15px 20px 15px;
  border-radius: 8px;
  background-color: #1f1f1f;
  margin: auto;
}

.Auth-form-content {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.Auth-form-title {
  text-align: justify;
    font-weight: 800;
    width: 100%;
    background-color: gray !important;
    padding: 10px;
    color: white !important;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    border-top: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
}
label {
  font-size: 14px;
  font-weight: 600;
  color: white
}
.btn-color-login {
  background-color:  #fec831 !important;
  font-weight: 700 !important;
}
.text-center {
  color: #ffff;
/* -color: #fec831; */
}
.text-center span {
  color: #fec831;
}
.Auth-form-content a{
  color: #fec831;
}
.Auth-form-content .form-group .form-control::placeholder{
  color: gray;
}
.modal-content .modal-body{
  color: white;
}
.modal-content .modal-header{
  color: #fec831 ;
}
.modal-content {
  background: #818181 ;
  background-color: #818181  !important;
  border: 1px solid gray;
}
a.login-link-color {
  padding: 12px;
  background: black;
  display: block;
  border: 2px solid;
  margin-top: 11px;
}
.Auth-form-content .form-group .form-control{
  border: 1px solid gray;
  background: black;
  color: white;
}
.button-container{
	display: flex;
	align-items: center;
}
.button-container .form-control{
	max-width: 80px;
	text-align: center;
	display: inline-block;
  background: #0D0D0D1A;
	margin: 0px 5px;
}
#myTable .form-control:focus{
  box-shadow: none;
}
#myTable .form-control{
	background: #0D0D0D1A;
    width: auto;
    display: inline-block;
    border: none;
    margin: auto;
}
.cart-qty-plus,
.cart-qty-minus{
  width: 28px;
  display: flex;
  background: #836B54;
  color: white;
  height: 28px;
  /* background-color: #fff; */
  /* border: 1px solid #ced4da; */
  border-radius: 7px;
  border: none;
  justify-content: center;
  align-items: center;
}
.payment-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payment-form .form-control {
  margin-bottom: 15px;
}

.card-element-container {
  margin-bottom: 15px;
}

.StripeElement {
  padding: 10px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #d9d9d936;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.special-online-shop .special-online-shop-box .img-box {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.StripeElement--focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.StripeElement--invalid {
  border-color: #dc3545;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* .proceedCheckout3 {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */

.proceedCheckout3:disabled {
  background-color: #6c757d;
}

.error-message {
  margin-top: 15px;
  color: #dc3545;
}

button.cart-qty-minus {
  z-index: 999;
  margin-right: -10px;
}
button.cart-qty-plus {
  margin-left: -10px;
}
.cart-qty-plus:hover,
.cart-qty-minus:hover{
	background-color: #372c27;
	color: #fff;
}
.Toastify__toast.Toastify__toast-theme--light {
  background: black;
  color: white;
}

.img-prdct{
	width: 50px;
	height: 50px;
/* 	background-color: #5161ce; */
	border-radius: 4px;
}
.img-prdct img{
  width: 100%;
}
#myTable tr th, #myTable tr td{
  background: none;
  height: 75px;
}
.popupMsg .modal-content .modal-body{
  color: white;
  font-size: 18px;
}
.popupMsg .modal-content .btn-close{
  color: white;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
}
.popupMsg .modal-content{
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  /* padding-top: 30px; */
  /* padding-bottom: 20px; */
  border-radius: 8px;
  background-color: #1f1f1f;
}
/* src/Components/HeaerAndFooter/ChatSupport.css */
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-history {
  flex: 1;
  padding: 0.5rem;
  overflow-y: auto;
  max-height: 300px; /* Set a height for the chat history */
}

.chat-message {
  margin: 5px 0;
  padding: 8px;
  border-radius: 6px;
  max-width: 80%;
}

.chat-message.user {
  background: #d1e7dd;
  align-self: flex-end;
}

.chat-message.bot {
  background: #e2e3e5;
  align-self: flex-start;
}

.chat-input {
  display: flex;
  padding: 5px;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.chat-input button {
  padding: 8px;
  margin-left: 5px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #000000;
  color: white;
}

/* ChatSupport.css */
.chat-support {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}

.chat-icon {
  font-size: 2.5rem;
  cursor: pointer;
  background-color: #FEC831;
  color: white;
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: glow 1s infinite !important;

}

.chat-box {
  width: 300px;
  max-height: 500px;
  position: fixed;
  bottom: 70px;
  left: 20px;
  background-color: white;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: none;
  flex-direction: column;
  animation: fadeIn 0.5s ease;
}

.chat-box.open {
  display: flex;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}

.chat-header {
  background-color: #171717;
  color: white;
  padding: 0.5rem;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.chat-header h4 {
  font-size: 20px;
  text-transform: uppercase;
}
.chat-history {
  flex: 1;
  padding: 0.5rem;
  overflow-y: auto;
}

.chat-message {
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 8px;
  max-width: 80%;
}

.chat-message.user {
  background-color: #878787;
  color: black;
  align-self: flex-start;
  margin-inline-start: 50px;
  text-align: left;
  color: white;
  
}

.chat-message.bot {
  background-color: rgb(72, 65, 45);
  color: white;
  align-self: flex-end;
  text-align: left;
  margin-inline-end: 50px;
}

.chat-input {
  display: flex;
  padding: 0.5rem;
  border-top: 1px solid #f1f1f1;
}

.chat-input input {
  flex: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 20px;
  margin-right: 0.5rem;
}

.chat-input button {
  background-color: #FEC831;
  color: black;
  border: none;
  border-radius: 20px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

@keyframes glow {
  0% {
     box-shadow: 5px 5px 20px #fec831, -5px -5px 20px #fec831;
 }
 50% {
     box-shadow: 5px 5px 20px #fec831, -5px -5px 20px #fec831;
 }
 
 100% {
     box-shadow: 5px 5px 20px #48412D, -5px -5px 20px #48412D;
 }
 }
 .chat-header h4 {
  font-size: 20px;
  text-transform: uppercase;
}

.chat-header hr {
  margin: 10px 0px;
  border-top: 3px solid;
  border-color: white;
}


.productPage .productBoxDescription .detailsOfSelectedProduct h5 span:after {
  width: 100%;
    background: #ffffff;
    position: absolute;
    bottom: 11px;
    left: 0;
    content: "";
    position: absolute;
    top: 41%;
    left: 0;
    right: 0;
    border-top: 2px solid #ffffff;
    transform: rotate(360deg);
}

.productPage .productBoxDescription .detailsOfSelectedProduct h5 span {
    position: relative;
}