.App {
  background: #171717;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .Toastify__progress-bar--success {
  background-color: #4CAF50; 
}

.Toastify__toast--success {
  background-color: #DFF0D8; 
} */
.Toastify--animate-icon svg {
  fill: #fec831 !important;
}
.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--success {
  background: #fec831 !important;
}
.Toastify__progress-bar--bg.Toastify__progress-bar-theme--light.Toastify__progress-bar--success {
  background: black !important;
}
.cartIconContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.cartItemCount {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  height: 20px;
  font-size: 12px;
  width: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Auth-form-header {
  background: url(./Components/assets/images/bglogin.jpg);
  text-align: left;
  box-shadow: none;
  color: antiquewhite;
  text-align: left;
    color: antiquewhite;
    background-size: 100%;
    width: 100%;
    height: 35vh;
    display: flex;
    border-radius: 17px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    margin-bottom: 15px;
}
.Auth-form-header .Auth-form-header-text {
  margin: auto;
  width: 510px;
  color: white;
}

.Auth-form-header .Auth-form-header-text h1 {
  font-weight: 600;
  font-size: 46px;
  font-family: 'Manhattan Hand Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.Auth-form-header .Auth-form-header-text h1 span {
  color: #FEC831;
}
.Auth-form-header .Auth-form-header-text ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
  padding-left: 0;
    margin: 0;
    list-style: none;
    font-size: 20px;
}

.form-group.otp.mt-3>div {
  justify-content: space-between;
}


.form-group.otp.mt-3>div input {
  width: 50px !important;
  height: 50px;
  border-radius: 100px;
  border: 1px solid gray;
  background: black;
  color: white;
}
.form-group.otp.mt-3>div span {
  color: gray;
}
.Auth-form-content .form-group span {
  color: white;
}