@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.side-menu {
    position: fixed;
    top: 0;
    display: flex;
    right: -500px;
    width: 500px;
    height: 100%;
    background-color: #171717;
    transition: .5s cubic-bezier(.77, 0, .175, 1);
    overflow-x: hidden;
    padding-top: 22px;
    transition: 0.5s;
    flex-direction: column;
    flex-wrap: inherit;
    padding-right: 22px;
    padding-left: 10px;
    padding-bottom: 20px;
    z-index: 9;
  }
  .tags{
    text-align: left;
  }
  .menu-item-has-children {
    position: relative;
}
.menu-item a {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    color: var(--thm-color-one);
    font-weight: 800;
    border-bottom: 2px solid var(--thm-border);
    text-transform: uppercase;
    font-size: 14px;
}
.menu-item-has-children>a:after {
    display: inline-block;
    margin-left: 0.55em;
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
}
ul .menu-item a {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    color: #1b2336;
    font-weight: 800;
    border-bottom: 2px solid #ffff;
    text-transform: uppercase;
    font-size: 14px;
}
  .side-menu.open {
    right: 0;
  }
  
  .close-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #000000;
  }
  
  .menu-list {
    list-style: none;
    padding: 0;
  }
  
  .menu-list li {
    padding: 15px;
    display: flex;
    align-items: center;
    color: #000000;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .menu-list li:hover {
    background-color: #555;
  }
  
  .menu-list li span {
    margin-left: 15px;
  }
  
  .logout-btn {
    position: relative;
    bottom: 23px;
    padding-bottom: 20px;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .submenu-icon {
    margin-left: auto;
  }
  
  .sub-menu {
    display: none;
    padding-left: 20px;
  }
  
  .sub-menu.open {
    display: block;
  }
  ul.main-menu {
    list-style: none;
    padding: inherit;
}
.paraSidemenu{
    font-family: 'Nunito Sans', sans-serif;
    padding: 0 6px;
    font-size: 13px;
    color: #fff;
    line-height: 1.5;
    margin-bottom: 20px;

}  
  .logout-btn:hover {
    background-color: #555;
  }
  
  .logout-btn span {
    margin-left: 15px;
  }
 
.sidebar .sidebar_widget {
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
}
.sidebar .recent_widgets ul li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.sidebar .recent_widgets ul li .image {
    width: 90px;
    height: 90px;
    margin-right: 30px;
}
.image-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.sidebar .recent_widgets ul li .text {
    text-align: left;
    width: calc(100% - 120px);
}
.sidebar .recent_widgets ul li .text h6 {
    font-size: 18px;
}
.sidebar .recent_widgets ul li .text p strong {
    font-weight: 700;
}
.sidebar .recent_widgets ul li .text p a {
    font-size: 14px;
    font-weight: 600;
    color: #415674;
}
.sidebar .sidebar_widget:last-child {
    margin-bottom: 0;
}

.aside_bar .sidebar .sidebar_widget {
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
}

.sidebar .sidebar_widget .widget_title {
  color: white;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ffff;
    border-top: 1px solid #ffff;
}
.sidebar .cartItem .productInfo .productDetails h5{
  color: white;
}
.arrowRight {
  color: #fec831;
  font-size: 36px;
  position: fixed;
  right: 35px;
  top: 50%;
  background: black;
  width: 65px;
  height: 65px;
  z-index: 1;
  border-radius: 11px;
  cursor: pointer;
}
.cancelButton .cancel{
  color: white;
}
.cancelButton{
  text-align: end;
  padding: 0px 0px 21px;
}
.sidebar .tags a {
    color: #415674;
    font-weight: 600;
    display: inline-flex;
    line-height: normal;
    background-color: rgb(75, 131, 251, 7%);
    border-radius: 6px;
    padding: 6px 12px;
    margin-right: 5px;
    margin-bottom: 8px;
}

.side-menu .menunav{
    display: none;
}
@media screen and (max-width: 768px) {
  .productPage .container .productBoxDescription .productSubsButton .singleButton{
    font-size: 16px;
    padding: 7px 11px;
  }
  .productPage .container .productBoxDescription .productSubsButton .subsButton{
    font-size: 16px;
    padding: 7px 11px;
  }
    .side-menu.open .res-flex{
      display: flex;
    }
    .side-menu.open {
        right: 0;
        width: 85%;
    }
    .sidebar .sidebar_widget ul {
      padding: inherit;
      font-size: 14px;
      text-align: left;
  }
  .sidebar .cartItem .productInfo {
    text-align: center;
}
    .side-menu{
        right: -500px;
    }
    /* .side-menu .sidebar{
        display: none;
    } */
    .side-menu .menunav .nav-link{
        padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    color: #1b2336;
    font-weight: 800;
    border-bottom: 2px solid #ffff;
    text-transform: uppercase;
    font-size: 14px;
    }
    .side-menu .menunav{
        text-align: left;
        display: block;
    }
}
.sidebar .cartItem .productInfo .productImage{
  object-fit: contain;
  width: 45%;
}
.sidebar .cartItem #myTable tr th, .sidebar .cartItem #myTable tr td{
  border-bottom-width: 0px;
    align-items: center;
    height: auto;
}
.sidebar .cartItem #myTable .form-control{
  color: white;
  background: #ffffff1a;
}
 .sidebar .cartItem #myTable{
  margin: 0px;
 }
 .sidebar .cartItem #myTable tr:nth-last-child(1){
  border-bottom: 0px;
 }
 .sidebar .cartItem {
  border: 1px solid white;
    padding: 11px;
    border-radius: 10px;
    margin-top: 12px;
 }
.sidebar .cartItem #myTable tr th, .sidebar .cartItem #myTable tr{
  border-bottom: 1px solid white;
}
.sidebar .cartItem #myTable tr th, .sidebar .cartItem #myTable tr td{
  color: white;
}
.sidebar .cartItem .productInfo .productInfo{
  width: 200px;
}